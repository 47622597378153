import React from "react"
import styled from "@emotion/styled"
import "./index.css"

export const Cta = styled.button`
  border: none;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
  color: var(--white);
  padding: 8px;
  border-radius: 200px;
  background: linear-gradient(180deg, #7a78ff, transparent);
  background-color: var(--dark-purple);

  border: none;
  display: inline-flex;
  height: auto;
  color: var(--white);
  padding: 9px 18px;

  box-shadow: rgb(0 0 0 / 50%) 0px 7px 8px -9px,
    rgb(64 56 255) 0px -8px 11px inset;
  border-radius: 200px;
  background-image: linear-gradient(
    hsl(238deg 100% 69%),
    hsl(238deg 100% 69%),
    #5e60fe 84%,
    #5b5dfe 90%,
    #3f3dff 100%
  );

  &.light {
    border: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    filter: brightness(1.05);
    height: auto;
    color: var(--white);
    padding: 9px 18px;
    box-shadow: #b8bcfe 1px 1px 0px 0px inset, #b8bcfeb0 1px 1px 1px inset;
    border-radius: 200px;
    background-image: linear-gradient(
      hsl(240deg 94% 73%),
      hsl(240deg 98% 74%),
      #7b7cff 84%,
      #7576ff 90%
    );
  }
`

export const InnerButton = styled.span`
  font-family: "slava", sans-serif;
  background-image: linear-gradient(
    hsl(0deg 0% 100% / 50%),
    #6f70fe 40%,
    #0008ff00 50%,
    #0005ff00
  );
  box-shadow: 2px -11px 2px -1px #ffffff0a;
  border-radius: 28px;
  background-color: transparent;
  padding: 10px 72px;
  display: block;
  font-size: 28px;
  line-height: 55px;
  color: #bd9eff;

  &.light {
    font-family: "slava", sans-serif;
    background-image: linear-gradient(
      hsl(0deg 0% 100% / 50%),
      #7b7bff 40%,
      #7576ff 50%
    );
    border-radius: 28px;
    background-color: transparent;
    padding: 10px 72px;
    display: block;
    font-size: 28px;
    line-height: 55px;
    color: #bd9eff;
    span {
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      background-image: url("https://image.shutterstock.com/image-illustration/white-black-glossy-background-blurred-260nw-1670612818.jpg");
      background-size: cover;
      background-position: center;
    }
  }
`

// export const Mask = styled(animated.div)`
//   padding: 3rem 25rem;
//   transition: all 0.2s ease-in-out;
// `

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: var(--purple);
  display: grid;
  place-items: center;
  transition: all 0.2s ease-in-out;
  overflow-x: hidden;
  --purple: #5b01ff;
  --dark-purple: #5c4dff;
  --white: #fff;


@font-face {
  font-family: "slava";
  src: url("https://matthieucaron.fr/static/slava-antipov-10809640af9a4997f777e8ed39e70f0e.ttf");
}

@property --c1 {
  syntax: "<percentage>";
  initial-value: 1%;
  inherits: false;
}

@property --c2 {
  syntax: "<percentage>";
  initial-value: 25%;
  inherits: false;
}

@property --t1 {
  syntax: "<percentage>";
  initial-value: 6%;
  inherits: false;
}

@property --t2 {
  syntax: "<percentage>";
  initial-value: 15%;
  inherits: false;
}

body {
  --c1: 1%;
  --t1: 6%;
  --t2: 15%;
  --c2: 25%;
}

@keyframes test {
  from {
    --c1: 1%;
    --t1: 17%;
    --t2: 27%;
    --c2: 37%;
  }

  to {
    --c1: 100%;
    --t1: 100%;
    --t2: 100%;
    --c2: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.container {
  padding: 3rem 25rem;
}
.container-02 {
  padding: 3rem 25rem;
  -webkit-mask-image: linear-gradient(
    65deg,
    #000 var(--c1),
    transparent var(--t1),
    transparent var(--t2),
    #000 var(--c2)
  );
  animation: 6s ease-in 0s infinite reverse test;
}

button {
  border: none;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
  color: var(--white);
  padding: 8px;
  border-radius: 200px;
  background: linear-gradient(180deg, #7a78ff, transparent);
  background-color: var(--dark-purple);
}
.inner-button {
  background: linear-gradient(180deg, #7a78ff, transparent);
  border-radius: 200px;
  background-color: var(--dark-purple);
  padding: 24px 104px;
  display: block;
}

.button-02 {
  border: none;
  display: inline-flex;
  height: auto;
  color: var(--white);
  padding: 9px 18px;

  box-shadow: rgb(0 0 0 / 50%) 0px 7px 8px -9px,
    rgb(64 56 255) 0px -8px 11px inset;
  border-radius: 200px;
  background-image: linear-gradient(
    hsl(238deg 100% 69%),
    hsl(238deg 100% 69%),
    #5e60fe 84%,
    #5b5dfe 90%,
    #3f3dff 100%
  );
}
.inner-button-02 {
  font-family: "slava", sans-serif;
  background-image: linear-gradient(
    hsl(0deg 0% 100% / 50%),
    #6f70fe 40%,
    #0008ff00 50%,
    #0005ff00
  );
  box-shadow: 2px -11px 2px -1px #ffffff0a;
  border-radius: 28px;
  background-color: transparent;
  padding: 10px 72px;
  display: block;
  font-size: 28px;
  line-height: 55px;
  color: #bd9eff;
}

@keyframes slidein {
  from {
    right: -300px;
    top: -60px;
    border-radius: 0px;
    filter: blur(10px);
    opacity: 0.3;
  }

  to {
    right: 500px;
    top: -92px;
    border-radius: 100px;
    filter: blur(32px);
    opacity: 0.6;
  }
}

.button-02.light {
  border: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-flex;
  filter: brightness(1.05);
  height: auto;
  color: var(--white);
  padding: 9px 18px;

  box-shadow: #b8bcfe 1px 1px 0px 0px inset, #b8bcfeb0 1px 1px 1px inset;
  border-radius: 200px;
  background-image: linear-gradient(
    hsl(240deg 94% 73%),
    hsl(240deg 98% 74%),
    #7b7cff 84%,
    #7576ff 90%
  );
}

.inner-button-02.light {
  font-family: "slava", sans-serif;
  background-image: linear-gradient(
    hsl(0deg 0% 100% / 50%),
    #7b7bff 40%,
    #7576ff 50%
  );
  border-radius: 28px;
  background-color: transparent;
  padding: 10px 72px;
  display: block;
  font-size: 28px;
  line-height: 55px;
  color: #bd9eff;
}

.inner-button-02.light span {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: url("https://image.shutterstock.com/image-illustration/white-black-glossy-background-blurred-260nw-1670612818.jpg");
  background-size: cover;
  background-position: center;
}

.wrapper:active {
  transform: scale(0.85)
}
`

const PurpleButton = () => {
  return (
    <>
      <div class="wrapper">
        <div class="container-02" style={{ position: "absolute", zIndex: 10 }}>
          <button class="button-02 normal">
            <span class="inner-button-02">Button</span>
          </button>
        </div>
        <div class="container" style={{ position: "absolute", zIndex: 5 }}>
          <button class="button-02 light">
            <span class="inner-button-02 light"><span>Button</span></span>
          </button>
        </div>
      </div>
      <p style={{ color: "white", position: "absolute", bottom: 40, left: 0, right: 0, fontFamily: "Inter, sans-serif", textAlign: "center" }}>Design by <a target="_top" href="https://twitter.com/gberthonneau/status/1541822863077957632"
        style={{ color: "white", fontFamily: "Inter, sans-serif" }} >@gberthonneau</a></p >
    </>
  )
}

export default function Button() {
  return (
    <Wrapper>
      <PurpleButton />
    </Wrapper>
  )
}
